jQuery(function($) {
    // topページ固定ヘッダー
    let mainNav = $('#main-nav'),
        mainNavPos = mainNav.offset().top;

        // console.log(mainNavPos);

    $(window).on('scroll', function() {

        scroll = $(window).scrollTop();

        if( scroll >= mainNavPos) {

            mainNav.addClass('nav-fixed');

        } else {

            mainNav.removeClass('nav-fixed');

        }
        
    });  
});